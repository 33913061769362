import request from '@/utils/request.js';
/**
 * 列表
 * @returns {Promise}
 */
export function getList(data) {
  return request({
    url: '/traffic-construction/pollutionSource/getPollutionSourcePage',
    method: 'post',
    data
  });
}
/* 删除记录
 * @returns {Promise}
 */
export function deleteRecord(data) {
  return request({
    url: '/traffic-construction/pollutionSource/deletePollutionSource',
    method: 'post',
    data
  });
}
/* 新增或修改
 * @returns {Promise}
 */
export function addOrEdit(data) {
    return request({
      url: '/traffic-construction/pollutionSource/addOrUpdatePollutionSource',
      method: 'post',
      data
    });
  }
  /* 获取污染源图表
 * @returns {Promise}
 */
export function getPhotoData(data) {
  return request({
    url: '/traffic-construction/pollutionSource/getPollutionSourcePhotoDate',
    method: 'post',
    data
  });
}