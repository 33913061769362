import request from '@/utils/request.js';

/**
 * 所属项目部
 * @returns {Promise}
 */
 export function getDataRangByNature(data) {
    return request({
      url: '/traffic-construction/getDataRangByNature',
      method: 'get',
      data
    });
  }