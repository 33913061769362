import { render, staticRenderFns } from "./selectPollute.vue?vue&type=template&id=16fd9990&scoped=true&"
import script from "./selectPollute.vue?vue&type=script&lang=js&"
export * from "./selectPollute.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16fd9990",
  null
  
)

export default component.exports