export const validateRules = {
  data() {
    var validateOrgId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择项目部'));
      }
      callback();
    };
    var validatePost = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择污染源类型'));
      }
      callback();
    };
    var validateProblem = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入污染源名称'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateSuggestion = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入位置'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenLevelId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入污染源类型'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenTypeId = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入噪声监测点'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenTypeId2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入厂界噪声'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateHiddenTypeId3 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入敏感点噪声'));
      }else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateRiskDescription = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入风险描述'));
      }else if (value.length > 500) {
        return callback(new Error('最大字符为500'));
      }
      callback();
    };

    return {
      rules: {
        projectDepartment: [{ validator: validateOrgId, trigger: '' }],
        pollutionType: [{ validator: validatePost, trigger: '' }],
        pollutionName: [{ validator: validateProblem, trigger: '' }],
        pollutionLocation: [{ validator: validateSuggestion, trigger: '' }],
        dropType: [{ validator: validateHiddenLevelId, trigger: '' }],
        noiseCheckPoint: [{ validator: validateHiddenTypeId, trigger: '' }],
        noiseBoundary: [{ validator: validateHiddenTypeId2, trigger: '' }],
        noiseSensitivePoint: [{ validator: validateHiddenTypeId3, trigger: '' }],
        riskDescription: [{ validator: validateRiskDescription, trigger: '' }],
      }
    };
  }
};
