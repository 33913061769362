<template>
  <div class="echart-display">
    <div class="search-list">
      <el-form ref="form" inline :model="queryForm">
        <el-form-item label="所属层级" v-if="isShowProject">
          <el-select v-model="queryForm.projectId" placeholder="请选择显示层级" collapse-tags @change="changeProject">
            <el-option v-for="item in project" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.ids" placeholder="请选择项目部" multiple collapse-tags @change="changeIds">
            <el-option
              v-for="item in projectList"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="污染源类型">
          <el-select
            v-model="queryForm.pollutionTypeList"
            placeholder="请选择污染源类型"
            clearable
            multiple
            collapse-tags
            @change="changeConclusions"
          >
            <el-option label="全选" value=""></el-option>
            <el-option label="废水" value="1"></el-option>
            <el-option label="废气" value="2"></el-option>
            <el-option label="噪声" value="3"></el-option>
            <el-option label="固废" value="4"></el-option>
            <el-option label="水土流失" value="5"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!-- 图表 -->
    <div>
      <echarts-line ref="echartRef" :x-data="xData" :data-list="dataList"></echarts-line>
    </div>
  </div>
</template>
<script>
import { getPhotoData } from '@/api/general-user/env/source-manage';
import { getDataRangByNature, getDataRang } from 'api/common';
import echartsLine from 'components/EchartsLine';
import { cloneDeep } from 'lodash';

export default {
  components: {
    echartsLine
  },
  data() {
    return {
      queryForm: {
        ids: [],
        pollutionTypeList: ["","1","2","3","4","5"],
        projectId: ""
      },
      isShowProject:false,//是否是集团人员
      project: [], //所属层级
      projectList: [], //所属项目部
      isCheckedAllProjectFlag: false, //所属项目部(是否全选)
      isCheckedAllConFlag: true, //所属类型(是否全选)
      data: {},
      xData: [], //图表横坐标
      dataList: [] //图表数据
    };
  },
  mounted() {},
  created() {
    this.getDataRang();
  },
  methods: {
    /* 获取层级 */
    getDataRang() {
      getDataRang().then(res => {
          this.project = res.data;
          if(this.project.length>0){
            this.isShowProject=true;
            this.queryForm.projectId=this.project[0].orgId;
          }
            this.getDataRangByNature({ orgId: this.queryForm.projectId });
      });
    },
    /* 获取机构 */
    getDataRangByNature(params) {
      getDataRangByNature(params).then(res => {
          this.projectList = res.data;
          if (this.projectList.length > 0) {
            this.projectList.unshift({
              orgId: '',
              orgName: '全选'
            });
          }
          this.projectList.map(item => {
            this.queryForm.ids.push(item.orgId);
          });
          this.isCheckedAllProjectFlag=true;
        this.getPhotoData();
      });
    },
    /* 选择层级 */
    changeProject() {
      this.queryForm.ids = [];
      this.xData = [];
      this.dataList = [];
      this.getDataRangByNature({ orgId: this.queryForm.projectId });
    },
    /* 选择机构 */
    changeIds(id) {
      if (!this.isCheckedAllProjectFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          let arr = [];
          this.projectList.map(item => {
            arr.push(item.orgId);
          });
          this.queryForm.ids = arr;
          this.isCheckedAllProjectFlag = true;
        } else {
          this.queryForm.ids = id;
        }
      } else {
        if(!id.includes('')){
          this.queryForm.ids=[];
        }else{
          this.queryForm.ids.shift();
          }
          this.isCheckedAllProjectFlag = false;
      }
      this.getPhotoData();
    },
    /* 选择类型 */
    changeConclusions(id) {
      if (!this.isCheckedAllConFlag) {
        if (id.includes('')) {
          //只要选择了全选，下面的所有都选择
          this.queryForm.pollutionTypeList=["","1","2","3","4","5"]
          this.isCheckedAllConFlag = true;
        } else {
          this.queryForm.pollutionTypeList = id;
        }
      } else {
        if(!id.includes('')){
          this.queryForm.pollutionTypeList=[];
        }else{
          this.queryForm.pollutionTypeList.shift();
          }
          this.isCheckedAllConFlag = false;
      }
      this.getPhotoData();
    },
    /* 获取图表数据 */
    getPhotoData() {
      let params = cloneDeep(this.queryForm);
      if (params.ids.includes('')) {
        //判断数组中是否有空字符串
        params.ids.shift(); //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      if (params.pollutionTypeList.includes('')) {
        //判断数组中是否有空字符串
        params.pollutionTypeList.shift(); //传[]或者所有id集合或者不传字段三种都可以（只有项目部才有，其他都传id集合）
      }
      getPhotoData({
        ids: params.ids,
        pollutionTypeList: params.pollutionTypeList
      }).then(res => {
          this.dataList = [];
          this.xData = [];
          res.data.name.forEach(item => {
            this.xData.push(item);
          });
          res.data.details.forEach((item, index) => {
            this.dataList.push({
              data: item.allNum?item.allNum:"0",
              type: 'bar',
              barWidth: 20,
              backgroundColor: '#1890FF',
              name: item.name,
              stack: 'stack',
              emphasis: {
                focus: 'series'
              }
            });
          });
        this.$nextTick(() => {
          this.$refs.echartRef.initEchartsLine(); //调用子组件echarts里面的配置方法
        });
      });
    }
  }
};
</script>
