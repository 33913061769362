<template>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="show" :close-on-click-modal="false" @close="handelClose" width="960px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="form-box"
        v-loading="dialogLoading"
      >
        <el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="所属项目部" prop="projectDepartment" :required="!isRead">
                <div class="block">
                  <el-select
                    v-model="ruleForm.projectDepartment"
                    style="width:320px"
                    clearable
                    placeholder="请输入所属项目部"
                    :disabled="isRead"
                  >
                  <el-option v-for="item in orgList" :key="item.orgId" :value="item.orgId" :label="item.orgName">
                  </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="污染源类型" prop="pollutionType" :required="!isRead">
                <el-select
                  v-model="ruleForm.pollutionType"
                  :maxlength="20"
                  placeholder="请选择污染源类型"
                  :disabled="isRead"
                  style="width:320px"
                >
                 <el-option v-for="item in pollutionSourceList" :key="item.id" :value="item.id" :label="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="12">
              <el-form-item label="污染源名称" prop="pollutionName" :required="!isRead">
                <el-input
                  v-model="ruleForm.pollutionName"
                  placeholder="请输入污染源名称"
                  :maxlength="20"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="位置" prop="pollutionLocation" :required="!isRead">
                <el-input
                  v-model="ruleForm.pollutionLocation"
                  placeholder="请输入位置"
                  :maxlength="20"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
            </el-row>
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="12" v-if="ruleForm.pollutionType!='3'&&ruleForm.pollutionType!='5'">
              <el-form-item :label="typename+'类型'" prop="dropType" :required="!isRead">
                <el-select
                  v-model="ruleForm.dropType"
                  :placeholder="'请输入'+typename+'类型'"
                  :disabled="isRead"
                  style="width:320px"
                >
                <el-option v-for="item in SourceTypelList[ruleForm.pollutionType]" :key="item.id" :value="item.id" :label="item.value">
                </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.pollutionType==='3'">
            <el-col :span="12">
              <el-form-item label="噪声监测点" prop="noiseCheckPoint" :required="!isRead">
                <el-input
                  v-model="ruleForm.noiseCheckPoint"
                  placeholder="请输入噪声监测点"
                  :maxlength="20"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="厂界噪声" prop="noiseBoundary" :required="!isRead">
                <el-input
                  v-model="ruleForm.noiseBoundary"
                  placeholder="请输入厂界噪声"
                  :maxlength="20"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.pollutionType==='3'">
            <el-col :span="12">
              <el-form-item label="敏感点噪声" prop="noiseSensitivePoint" :required="!isRead">
                <el-input
                  v-model="ruleForm.noiseSensitivePoint"
                  placeholder="请输入敏感点噪声"
                  :maxlength="20"
                  :disabled="isRead"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="24">
              <el-form-item label="风险描述" prop="riskDescription" :required="!isRead">
                <el-input
                  v-model="ruleForm.riskDescription"
                  placeholder="请输入风险描述"
                  :maxlength="500"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
             </el-row>
            <!-- TODO:图片 -->
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="12">
              <el-form-item label="图片描述" prop="fileIds">
                <UploadMultipleImage v-if="!isRead" :default-list="defaultImage"  @get-file-data="getImageData"   :is-disabled="isDisabled"></UploadMultipleImage>
                <div v-else>
                  <div class="image-box" v-if="defaultImage.length">
                    <img :src="imageSingle.url" alt="图片" v-for="imageSingle in defaultImage" :key="imageSingle.fileId" @click="handlePictureCardPreview(imageSingle.url)">
                  </div>
                  </div>
              </el-form-item>
            </el-col>
             </el-row>
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="24">
              <el-form-item label="主要污染物" prop="detectFactors" class="detailsformlast">
                <el-table
                  :data="ruleForm.detectFactors"
                  v-if="ruleForm.detectFactors.length!=0"
                  border
                  :show-header="false"
                  style="width: 100%">
                  <el-table-column
                    width="180">
                     <template  slot-scope="scope">
                        <span>{{scope.row.pollutant.name}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template  slot-scope="scope">
                        <span>{{scope.row.pollutantQuotas[0].quota.name}}</span>
                        <span>{{scope.row.pollutantQuotas[0].quotaValue.dimensionRuleValue}}</span>
                        <span>{{scope.row.pollutantQuotas[0].quotaValue.quotaValue}}</span>
                        <span>{{scope.row.pollutantQuotas[0].quotaValue.dimensionName}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" align="center" v-if="!isRead">
                    <template slot-scope="scope">
                        <i class="deletedetails el-icon-delete" @click="deleteDetail(scope.$index)"></i>
                    </template>
                  </el-table-column>
                </el-table>
                <span v-if="!isRead" class="action-btn" @click="addMainPollutes">+点击添加主要污染物</span>
              </el-form-item>
            </el-col>
             </el-row>
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="24">
              <el-form-item label="治理措施" prop="treatmentMeasure">
                <el-input
                  v-model="ruleForm.treatmentMeasure"
                  placeholder="请输入治理措施"
                  :maxlength="500"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
             </el-row>
          <el-row v-if="ruleForm.pollutionType">
            <el-col :span="24">
              <el-form-item label="应急措施" prop="emergencyMeasure">
                <el-input
                  v-model="ruleForm.emergencyMeasure"
                  placeholder="请输入应急措施"
                  :maxlength="500"
                  :disabled="isRead"
                  type="textarea"
                  resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
            </el-row>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <span v-if="isRead">
          <el-button type="danger" @click="deleteInfo" :loading="btnLoading">删 除</el-button>
          <el-button type="primary" @click="updataInfo">修 改</el-button>
        </span>
        <span v-else>
          <el-button @click="handelchanel">取 消</el-button>
          <el-button type="primary" @click="handelsure" :loading="btnLoading">确 定</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 添加污染物多级弹框 -->
    <SelectPollute
      ref="selectPol"
      @getPollutes="handlePollutes"
    ></SelectPollute>
      <!-- 预览图片 -->
    <el-dialog :visible.sync="imageVisible">
      <img width="100%" :src="dialogImageUrl" alt="预览大图" />
    </el-dialog>
  </div>
</template>
<script>
import { getDataRangByNature } from '@/api/general-user/env/common';
import { addOrEdit,deleteRecord} from '@/api/general-user/env/source-manage';
import { validateRules } from './validateRules.js';
import UploadMultipleImage from 'components/Upload/UploadMultipleImage.vue';
import { downloadFile } from '@/api/general-user/internal-data.js';
import { exportFun } from '@/utils/export.js';
import  SelectPollute  from '../component/selectPollute.vue';

export default {
  mixins: [validateRules],
  components: { UploadMultipleImage ,SelectPollute},
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    /* 传递的数据 */
    info: {
      type: Object,
      required: true,
      default: {}
    }
  },
  data() {
    return {
      /* 显隐 */
      show: this.dialogVisible,
      /* 显示数据 */
      ruleForm: {
        detectFactors:[],
      },
      /* 是否是查看详情 */
      isRead: false,
      /* 机构数 */
      orgList: [],
      selfOrg: {},
      /* 确认按钮状态 */
      btnLoading: false,
      infoId: '',
      dialogLoading: false,
      sysFileInfos: [],
      /* 污染源类型 */
      pollutionSourceList:[{id:'1',name:"废水"},{id:'2',name:"废气"},{id:'3',name:"噪声"},{id:'4',name:"固废"},{id:'5',name:"水土流失"}],
      SourceTypelList:{
        "1":[{"id":"1","value":"工业废水"},{"id":"2","value":"生活废水"}],
        "2":[{"id":"3","value":"有组织废气"},{"id":"4","value":"无组织废气"}],
        "4":[{"id":"5","value":"生活垃圾"},{"id":"6","value":"建渣"},{"id":"7","value":"表土"},{"id":"8","value":"土石方"}]
      },
      defaultImage:[],
      isDisabled:false,//上传组件是否隐藏，查看详情为true
      imageVisible:false,
      dialogImageUrl:'',
    };
  },
  methods: {
    /* 拷贝父级传递的值 */
    initFormDetail(val) {
      this.isDisabled=true;
      this.dialogLoading = true;
      setTimeout(() => {
        this.dialogLoading = false;
      }, 500);
      this.resetRuleform();
      this.ruleForm=val;
      this.ruleForm.dropType+="";
      this.ruleForm.pollutionType+="";
       //这里图片上传组件的回显
      if(val.sysFileInfos.length){
        val.sysFileInfos.forEach(item=>{
          let obj={
            name:item.fileOriginName,
            url:item.filePath,
            fileId:item.fileId
          }
          this.defaultImage.push(obj);
        })
        console.log(this.defaultImage);
      }
      this.infoId = val.id; //该记录ID
    },
    handlePictureCardPreview(imageUrl){//预览图片
      this.dialogImageUrl=imageUrl;
      this.imageVisible = true;
    },
    /* 退出重置 */
    handelClose() {
      this.resetRuleform();
      this.$refs.ruleForm.resetFields();
      this.handelchanel();
    },
    /* 关闭弹出框 */
    handelchanel() {
      this.$emit('update:dialogVisible', false);
    },
    /* 新增或修改 */
    handelsure() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.dialogTitle.includes('新增')) {
            this.add();
          } else if (this.dialogTitle.includes('修改')) {
            this.Edit();
          }
        } else {
          return false;
        }
      });
    },
    /* 新增方法 */
    add() {
      this.btnLoading = true;
      addOrEdit(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('新增成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [true, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 修改 */
    updataInfo() {
      this.dialogLoading = true;
      setTimeout(() => {
        this.isDisabled=false;
        this.dialogLoading = false;
      }, 500);
      this.$emit('update:dialogTitle', '修改');
      this.isRead = false;
    },
    /* 修改接口 */
    Edit() {
      this.btnLoading = true;
      this.ruleForm.id = this.infoId;
      addOrEdit(this.ruleForm)
        .then(res => {
            this.btnLoading = false;
            this.$message.success('修改成功');
            this.$emit('update:dialogVisible', false);
            this.$emit('handel-success', [false, false]);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    /* 删除提醒 */
    deleteInfo() {
      this.$confirm('请确认是否删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteRecord();
      });
    },
    /* 删除接口 */
    deleteRecord() {
      deleteRecord({ id: this.infoId }).then(res => {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', [false, true]); //为删除时回传
      });
    },
    /* 重置方法 */
    resetRuleform() {
      this.imageVisible=false,
      this.isDisabled=false;
      this.defaultImage=[];
      this.sysFileInfos = [];
      this.ruleForm = {
        detectFactors:[]
      };
    },
    /* 获取机构树 */
    getDataRangByNature() {
      getDataRangByNature().then(res => {
          this.orgList=res.data;
      });
    },
    /* 回传图片 */
    getImageData(fileId){//新增或者编辑
      this.ruleForm.pictureIds=fileId.join(',');
    },
    /* 下载附件 */
    uploadFile(fileId) {
      downloadFile({ fileId })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '下载数据成功'
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    // 点击添加污染物弹框事件
    addMainPollutes() {
      this.$refs.selectPol.addMainPollute();
    },
    // 回传选择的污染物指标
    handlePollutes(val) {
      val.map((item)=>{
        this.ruleForm.detectFactors.push({
          "pollutant":{
            "name":item.pollutionName.value
            },
          "pollutantQuotas":[{
            "quota":{
              "name":item.pollutionTypeName
            },
            "quotaValue":{
              "dimensionName":item.dimension_name,
              "dimensionRuleValue":item.dimension_rule_value,
              "quotaValue":item.value
            }
          }]
        })
      })
    },
    deleteDetail(val){
      this.ruleForm.detectFactors.splice(val,1)
    },
  },
  created() {
    this.getDataRangByNature();
  },
  watch: {
    dialogVisible(val) {
      this.show = val;
    },
    isRead(val) {
      this.isRead = val;
    },
    'ruleForm.pollutionType'(val){
      if(!this.isRead){
        let partment=this.ruleForm.projectDepartment;
        this.ruleForm={
          "projectDepartment":partment,
          "detectFactors":[],
          "pollutionType":val,
          };
      }
    }
  },
  computed:{
    typename(){
      var name="";
      this.pollutionSourceList.forEach(item => {
       if(this.ruleForm.pollutionType===item.id){
         name=item.name
       }
      });
      return name;
    }
  }
};
</script>
<style lang="less" scoped>
.deletedetails{
  font-size: 24px;
}
.image-box{
  display: flex;
  img{
    width:80px;
    height:80px;
    display:block;
    margin-right: 6px;
    cursor: pointer;
  }
}
</style>
