<template>
  <div>
    <!-- 列表搜索 -->
    <el-form :inline="true" :model="pages" class="demo-form-inline">
      <el-form-item label="所属项目部">
        <el-select v-model="pages.ids" placeholder="请选择所属项目部" clearable multiple collapse-tags>
          <el-option v-for="item in orgList" :key="item.orgId" :label="item.orgName" :value="item.orgId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="污染源名称">
        <el-input v-model="pages.pollutionName" placeholder="请输入污染源名称" clearable></el-input>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="onSubmit">查询</el-button>
        <el-button @click="resetInput">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 功能区按钮 -->
    <el-button v-if="$check('env-source-manage-view-add')" type="primary" @click="addInfo" icon="el-icon-plus"
      >新增污染源</el-button
    >
    <!-- 显示列表 -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      stripe
      style="width: 100%"
      v-loading="tabelLoading"
      max-height="550"
    >
      <el-table-column label="序号" align="center" width="55" fixed>
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="orgName" label="所属项目部" align="center" width="180" fixed sortable> </el-table-column>
      <el-table-column prop="pollutionName" label="污染源名称" align="center" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column prop="pollutionType" label="污染源类型" align="center" show-overflow-tooltip sortable>
        <template slot-scope="scope">
          {{ scope.row.pollutionType | pollutionTypeToName(pollutionSourceList) }}
        </template>
      </el-table-column>
      <el-table-column prop="pollutionLocation" label="位置" align="center" show-overflow-tooltip sortable>
      </el-table-column>
      <el-table-column prop="detectFactors" label="主要污染物" align="center" width="200" sortable>
        <template slot-scope="scope">
          <div v-for="(item, index) in noRepetName(scope.row.detectFactors)" :key="index">
            {{ item }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <span v-if="$check('env-source-manage-view-edit')" class="action-btn" @click="handleDetails(scope.row)"
            >查看详情</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pages.pageNo"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pages.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 详情、新增、修改框 -->
    <list-dialog
      ref="formRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :info="dialogData"
      @handel-success="handelSuccess"
    ></list-dialog>
  </div>
</template>
<script>
import { getList } from '@/api/general-user/env/source-manage';
import { getDataRangByNature } from '@/api/general-user/env/common';
import listDialog from './listDialog.vue';
import { cloneDeep } from 'lodash';
export default {
  components: { listDialog },
  data() {
    return {
      /* 机构数 */
      orgList: [],
      /* 表格 */
      tableData: [],
      total: 0,
      tabelLoading: false,
      /* 分页、搜索栏 */
      pages: {
        pageSize: 10,
        pageNo: 1,
        ids: []
      },
      /* 回显数据 */
      dialogVisible: false,
      dialogTitle: '',
      dialogData: {},
      /* 导出数据ID */
      ids: [],
      pollutionSourceList: [
        { id: '1', name: '废水' },
        { id: '2', name: '废气' },
        { id: '3', name: '噪声' },
        { id: '4', name: '固废' },
        { id: '5', name: '水土流失' }
      ]
    };
  },
  methods: {
    /* 搜索 */
    onSubmit() {
      this.pages.pageNo = 1;
      this.pages.pageSize = 10;
      this.getList();
    },
    /* 获取数据列表 */
    getList() {
      this.tabelLoading = true;
      getList(this.pages).then(res => {
          this.tabelLoading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
      });
    },
    /* 操作回调 */
    handelSuccess(arr) {
      //arr[0]为返回列表第一页，否则为编辑，在当前页
      //arr[1]为是否是删除操作
      if (arr[0]) {
        this.pages.pageNo = 1;
      }
      if (arr[1]) {
        if (this.tableData.length === 1 && this.pages.pageNo > 1) {
          this.pages.pageNo = this.pages.pageNo - 1; //删除一页的最后一条时，跳转前一页（大于1页）
        }
      }
      this.getList();
      //刷新列表
    },
    /* 分页大小 */
    handleSizeChange(val) {
      this.pages.pageNo = 1; //重置为第一页
      this.pages.pageSize = val;
      this.getList();
    },
    /* 分页当前页 */
    handleCurrentChange(val) {
      this.pages.pageNo = val;
      this.getList();
    },
    /* 重置搜索框 */
    resetInput() {
      this.$refs.multipleTable.clearSort();
      this.pages = {};
      this.pages.pageNo = 1; //重置为第一页
      this.pages.pageSize = 10;
      this.getList();
    },
    /* 弹出框显隐 */
    DialogShow() {
      this.dialogVisible = true;
    },
    /* 新增 */
    addInfo() {
      this.dialogTitle = '新增';
      this.$refs.formRef.isRead = false;
      this.DialogShow();
    },
    /* 查看详情 */
    handleDetails(row) {
      this.dialogTitle = '查看详情';
      this.$refs.formRef.isRead = true;
      this.DialogShow();
      const obj = cloneDeep(row);
      this.$refs.formRef.initFormDetail(obj); //调用子组件的方法时使表单回显
    },
    /* 获取机构列表 */
    getDataRangByNature() {
      getDataRangByNature().then(res => {
          this.orgList = res.data;
      });
    },
    /* 去重 */
    noRepet(val) {
      let nameArr = [];
      val.forEach(item => {
        nameArr.push(item.pollutant.name);
      });
      let setName = [...new Set(nameArr)];
      return setName;
    }
  },
  created() {
    this.getList();
    this.getDataRangByNature();
  },
  computed: {
    noRepetName(val) {
      if (val) {
        return function(val) {
          return this.noRepet(val);
        };
      }else{
        return [];
      }
    }
  },
  filters: {
    pollutionTypeToName(val, list) {
      return list[val-1].name;
    }
  }
};
</script>
<style lang="less" scoped>
.btn {
  margin-left: 50px;
}
</style>
