<template>
  <!--    污染物搜索-->
  <el-dialog
    :title="addSourceTitle"
    :visible.asny="addSourceModal"
    :before-close="resetSourceForm"
    close-on-press-escape
    width="650"
  >
    <el-row v-show="polluteType == 1">
      <el-col :span="24" style="margin-bottom: 20px">
        <el-radio-group v-model="polluteData.searchType" @on-change="changeSearchType">
          <el-radio label="1">搜索污染物</el-radio>
          <el-radio label="2">搜索标准文件</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="24" class="auto-input">
        <el-autocomplete
          style="width:100%"
          v-model="polluteData.searchInput"
          :fetch-suggestions="handleSearch"
          placeholder="请输入"
          :trigger-on-focus="false"
          @select="selectPolluteType"
        >
        </el-autocomplete>
      </el-col>
    </el-row>
    <!--请选择该污染物的标准文件-->
    <el-row v-show="polluteType == 2">
      <el-col :span="24">
        <el-radio-group v-model="polluteData.pollute">
          <el-radio
            :label="item.id"
            v-for="(item, index) in standardFileAll"
            :key="index"
            @click.native="selectPolluteto(item)"
          >
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <!--请选择该污染物的标准文件中的一条标准或者选择具体污染物-->
    <el-row v-show="polluteType == 3">
      <el-col :span="24">
        <p class="standardFileOneTitle"><span>标准文件：</span>{{ filePolluteTitle }}</p>
      </el-col>
      <el-col :span="24" v-if="polluteData.searchType == 2">
        <el-input></el-input>
      </el-col>
      <el-col :span="24">
        <el-radio-group v-model="polluteData.standardFileOne">
          <el-radio
            :label="item.id"
            v-for="(item, index) in filePolluteAll"
            :key="index"
            @click.native="selectStandardFileOne(item)"
          >
            <span class="FileSelectTitle"
              >{{ item.name }}<span>{{ item.description }}</span></span
            >
          </el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
    <!--      对应指标-->
    <el-row v-show="polluteType == 4">
      <el-col :span="24">
        <p class="standardFileOneTitle" style="border-bottom: none"><span>标准文件：</span>{{ filePolluteTitle }}</p>
        <p class="standardFileOneTitle"><span>污染物：</span>{{ polluteSearchName.value }}</p>
      </el-col>
      <el-col :span="24">
        <el-row :key="index" v-for="(item, index) in detail">
          <el-col>
            <el-row>
              <div class="table-title-set">
                指标：{{ item.name }} &nbsp;&nbsp;
                <span style="color: #999999;">{{ item.description }}</span>
              </div>
              <div>
                <table border="1" cellspacing="0" width="100%">
                  <tr>
                    <td
                      style="width: 100px"
                      :rowspan="item.column_num === 1 || item.column_num === '1' ? 1 : 2"
                      colspan="2"
                      class="td-background"
                    >
                      单位({{ item.dimension_name }})
                    </td>
                    <td class="td-background" :colspan="item.topLists.data.length">
                      {{ item.column_name }}
                    </td>
                  </tr>
                  <tr v-if="item.topLists.data.length > 1">
                    <td v-for="top in item.topLists.data" :key="top.id">
                      {{ top.name }}
                    </td>
                  </tr>
                  <tr v-for="(tr, key) in item.leftLists" :key="key">
                    <td v-if="key === 0" class="td-background" style="width: 50px" :rowspan="item.leftLists.length">
                      {{ item.row_name }}
                    </td>
                    <td style="width: 50px" :class="{ 'td-background': item.leftLists.length === 1 }">
                      {{ tr.name }}
                    </td>
                    <td
                      style="cursor: pointer"
                      @click="selectcurrentData(td, item)"
                      :style="{ background: td.active ? '#B7EAFA' : '' }"
                      v-for="td in tr.children"
                      :key="td.id"
                    >
                      <span
                        v-if="td.dimension_rule !== 5 && td.dimension_rule !== 6"
                        :style="{
                          color: td.is_edit_dimension_rule ? '#BC041A' : ''
                        }"
                        >{{ td.dimension_rule_value }}</span
                      >
                      <span
                        :style="{
                          color: td.dimension_rule === 5 || td.dimension_rule === 6 ? '#BC041A' : ''
                        }"
                        >{{ td.value }}</span
                      >
                      <span style="color: #999999" v-if="td.is_edit_dimension">({{ td.dimension_name }})</span>
                      <el-tooltip
                        v-if="td.is_edit_remark"
                        class="item"
                        :content="td.description"
                        :disabled="!td.is_edit_remark"
                        placement="top"
                      >
                        <i
                          :class="{
                            IconColor: td.is_edit_remark,
                            IconFontColor: !td.is_edit_remark
                          }"
                          class="iconfont icon-icon_beizhu-copy"
                        ></i>
                      </el-tooltip>
                    </td>
                  </tr>
                </table>
              </div>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div slot="footer" v-if="polluteType != 1">
      <el-button type="primary" @click="goBackUp(polluteType)">返回上一页</el-button>
      <el-button type="primary" @click="handlePollutes" v-show="polluteType == 4">确定</el-button>
    </div>
    <div slot="footer" v-if="polluteType == 1"></div>
  </el-dialog>
</template>

<script>
import axios from 'axios';
export default {
  name: 'selectPollute',
  data() {
    return {
      addSourceModal: false,
      spinShow: false,
      addSourceTitle: '新增污染物',
      polluteSearchName: '',
      polluteThinkAll: [],
      polluteThink: [],
      standardFileAll: [],
      filePolluteAll: [],
      filePolluteTitle: '',
      polluteType: 1,
      polluteData: {
        searchType: '1',
        searchInput: '',
        pollute: '',
        standardFile: '',
        standardFileOne: ''
      },
      detail: [],
      selectTable: [],
      selectEnd: false
    };
  },
  methods: {
    // 点击添加污染物弹框事件
    addMainPollute() {
      this.addSourceModal = true;
    },
    // 关闭重置污染选择框
    resetSourceForm() {
      this.addSourceModal = false;
      this.polluteType = 1;
      this.addSourceTitle = '新增污染物';
      this.polluteData = {
        searchType: '1',
        searchInput: '',
        pollute: '',
        standardFile: '',
        standardFileOne: ''
      };
      this.polluteThink = [];
      this.polluteSearchName = '';
      this.polluteThinkAll = [];
      this.standardFileAll = [];
      this.filePolluteAll = [];
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    // 搜索污染物名字
    handleSearch(value, cb) {
      const self = this;
      self.spinShow = true;
      if (this.polluteData.searchType == '1') {
        // 搜索污染物
        axios
          .get('http://api.zwehs.com/v1/detect/intelligent/searches?name=' + value)
          .then(res => {
            if (res.status===200) {
              //debugger
              self.polluteThink = [];
              self.polluteThinkAll = res.data.data;
              res.data.data.map(el => {
                self.polluteThink.push({"value":el.name});
              });
              self.spinShow = false;
            } else {
              self.spinShow = false;
              self.$message.error('未查询到相关污染物');
            }
            cb(self.polluteThink);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        // 搜索标准文件
        axios.get(`http://api.zwehs.com/v1/detect/intelligent/searches?name=${value}&search_type=2`).then((res) => {
          console.log(res);
          if (res.status===200) {
            self.polluteThink = [];
            res.data.data.map(el => {
              self.polluteThink.push({"value":el.name});
            });
            self.spinShow = false;
          } else {
            self.spinShow = false;
            // self.$message.info('未查询到相关标准文件');
          }
            console.log(self.polluteThink);
            cb(self.polluteThink);
        }).catch((err) => {
          console.log(err);
        })
      }
    },

    // 选中污染物名称跳转 1->2
    selectPolluteType(value) {
      const self = this;
      this.spinShow = true;
      if (this.polluteData.searchType == '1') {
        // 搜索污染物
        this.polluteSearchName = value;
        let polluteId = '';
        this.polluteThinkAll.map(el => {
          if (el.name == value) {
            polluteId = el.search_id;
          }
        });
        axios.get(
          `http://api.zwehs.com/v1/detect/forms/by/polutant_name?name=${value.value}&search_type=1&search_id=${polluteId}`).then((res) => {
             if(res.status===200){
                self.standardFileAll = res.data.data[0].data;
                self.spinShow = false;
             }
          }).catch((err) => {
            console.log(err);
          });
        self.polluteType = 2;
        self.addSourceTitle = '请选择该污染物的标准文件';
      } else {
        self.selectPolluteto(value);
        console.log(111);
      }
    },
    // 选中标准文件跳转 2->3
    selectPolluteto(value) {
      console.log(value);
      this.spinShow = true;
      const self = this;
      if (this.polluteData.searchType == '1') {
        this.polluteType = 3;
        this.addSourceTitle = '该污染物在标准文件中存在多条，请选择一条';
        this.filePolluteTitle = value.name;
      } else {
        this.polluteType = 3;
        this.addSourceTitle = '请选择具体污染物';
      }
      axios.get(`http://api.zwehs.com/v1/detect/pollutants?name=${this.polluteSearchName.value}&form_id=${value.id}`).then((res) => {
        if(res.status===200){
          self.filePolluteAll = res.data.data;
          self.spinShow = false;
        }
      }).catch((err) => {
        console.log(err);
      });
    },
    // 选中标准文件其中一个跳转 3->4
    selectStandardFileOne(value) {
      this.spinShow = true;
      const self = this;
      if (this.polluteData.searchType == '2') {
        this.polluteSearchName = value.name;
      }
      this.polluteType = 4;
      this.addSourceTitle = '选择对应指标';
      axios.get(
        `http://api.zwehs.com/v1/detect/pollutant/${value.id}`).then((res) => {
          self.detail = res.data.data.detectPollutantQuotas.data;
          self.spinShow = false;
        }).catch((err) => {
          console.log(err);
        });
    },
    clearSelected(id) {
      let index = _.findIndex(this.selectTable, obj => obj.quotaId === id);
      this.selectTable.splice(index, 1);
    },
    selectcurrentData(type, item) {
      if (this.selectEnd) {
        this.selectTable = [];
        this.selectEnd = false;
      }
      if (type.active) {
        this.$set(type, 'active', false);
        this.clearSelected(item.id);
      } else {
        this.selectTable.forEach(select => {
          if (select.quotaId === item.id && select.id !== type.quota_value_id) {
            this.$set(select, 'active', false);
            this.clearSelected(item.id);
          }
        });
        this.$set(type, 'quotaId', item.id);
        this.$set(type, 'active', true);
        this.$set(type, 'pollutionName', this.polluteSearchName);
        this.$set(type, 'pollutionTypeName', item.name);
        this.selectTable.push(type);
      }
    },
    goBackUp(value) {
      if (this.polluteData.searchType == '1') {
        this.polluteType = value - 1;
      } else {
        this.polluteType = value - 1;
        if (this.polluteType == 2) {
          this.polluteType = 1;
        }
      }
      switch (this.polluteType) {
        case 1:
          this.addSourceTitle = '新增污染物';
          break;
        case 2:
          this.addSourceTitle = '请选择该污染物的标准文件';
          break;
        case 3:
          if (this.polluteData.searchType == '1') {
            this.addSourceTitle = '该污染物在标准文件中存在多条，请选择一条';
          } else {
            this.addSourceTitle = '请选择具体污染物';
          }
          break;
        case 4:
          this.addSourceTitle = '选择对应指标';
          break;
      }
    },
    handlePollutes() {
      if (this.selectTable.length <= 0) {
        this.$message.info('请选择指标');
      } else {
        // 父组件获取污染物记录
        this.$emit('getPollutes', this.selectTable);
        this.selectEnd = true;
        this.addSourceModal = false;
      }
      this.resetSourceForm();
    },
    changeSearchType(val) {
      this.polluteData.searchType = val;
      this.polluteData.searchInput = '';
      this.polluteSearchName = '';
      this.polluteThinkAll = [];
      this.polluteThink = [];
      this.standardFileAll = [];
      this.filePolluteAll = [];
    }
  }
};
</script>

<style scoped></style>
