<template>
  <div>
    <el-upload
      :action="uploadUrl"
      :headers="uploadHeaders"
      multiple
      :file-list="defaultList"
      :limit="limitNum"
      :data="{ appBucketName: 'traffic-construction' }"
      :on-change="dealImgChange"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="onError"
      :on-progress="handelProgress"
      :on-remove="handleRemove"
      :on-preview="handlePictureCardPreview"
      list-type="picture-card"
      :accept="acceptType"
      :disabled="isDisabled"
      :class="{ disUoloadSty: noneBtnDealImg || isDisabled }"
      v-loading.fullscreen="loading"
      element-loading-text="拼命上传中"
      element-loading-background="rgba(0, 0, 0, 0.1)"
    >
      <i class="el-icon-plus"></i>
    </el-upload>

    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>

<script>
import { getItem } from '@/utils/storage.js';
export default {
  props: {
    defaultList: {
      type: Array,
      default: () => []
    },
    limitNum: {
      type: Number,
      default: 5
    },
    acceptType: {
      type: String,
      default: '.jpg, .png, .gif, .jpeg'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uploadUrl() {
      return '/api/roses-pub-service/sysFileInfo/uploadResponse';
    },
    uploadHeaders() {
      return {
        Authorization: 'Bearer ' + this.token,
        AppCode: 'traffic-construction',
        tenantId: this.tenantId
      };
    }
  },
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileIdList: [],
      loading: false,
      //结合isDisabled字段判断上传组件是否显隐的标志-包括新增以及查看详情（class动态绑定）
      noneBtnDealImg: false
    };
  },
  methods: {
    //判断已上传图片成功的数量与最大上传数量的比较，显示和隐藏上传组件
    /* 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用 （删除一样） */
    dealImgChange(file, fileList) {
      this.noneBtnDealImg = fileList.length >= this.limitNum;
    },
    //删除
    handleRemove(file, fileList) {
      this.noneBtnDealImg = fileList.length >= this.limitNum;
      let removeFileId = file.fileId;
      this.fileIdList.splice(this.fileIdList.indexOf(removeFileId), 1);
      this.$emit('get-file-data', this.fileIdList);
    },
    //预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //上传中
    handelProgress(event, file, fileList) {
      this.loading = true;
    },
    //上传前校验
    beforeUpload(file) {
      const requireType = ['image/jpg', 'image/png', 'image/gif', 'image/jpeg'];
      const isRequireType = requireType.indexOf(file.type) >= 0;
      if (!isRequireType) {
        this.$message.error('上传文件类型错误!');
      }
      return isRequireType;
    },
    //上传成功
    handleSuccess(response, file, fileList) {
      setTimeout(() => {
        this.loading = false;
      }, 500);
      this.fileIdList.push(response.data.fileId);
      this.$emit('get-file-data', this.fileIdList);
    },
    //上传失败
    onError(err, file, fileList) {
      this.loading = false;
    }
  },
  created() {
    this.token = getItem('token');
    this.tenantId = getItem('tenantInfo').tenantId;
  },
  watch: {
    defaultList: {
      handler: function(newValue) {
        if (!newValue.length) {
          this.fileIdList = []; //要清除,不是要累加
        } else {
          newValue.map(item => {
            this.fileIdList.push(item.fileId);
          });
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
